<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    v-model="open_modal"
    title="Ler QR Code"
    size="l"
  >
    <p class="error">{{ error }}</p>

    <!-- <p class="decode-result">Leia o código QR.</p> -->

    <qrcode-stream @decode="onDecode" @init="onInit" />
    <template #modal-footer>
      <b-button @click="closeModal"> Fechar </b-button>
    </template>
  </b-modal>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name:"QrCode",
  data() {
    return {
      result: "",
      error: ""
    };
  },
  components: { QrcodeStream },
  props: { open_modal: Boolean, closeModal: Function, onRead: Function },
  methods: {
    onDecode(result) {
      this.onRead(result);
      this.closeModal();
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    }
  }
};
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>

import User from "@/store/modules/User";
import {
  IOrderAction,
  IOrderListPagination,
  IOrderShow,
  IOrderStoreRequest
} from "../models/order";
import ApiBase from "./ApiBase";

const ORDERS = "/dash/admin/orders";
const ORDER_STATUS = "/dash/driver/order/status";
const ORDER_ACTIONS = "/dash/driver/order/actions";

export const OrderStoreRequest = async (
  data: IOrderStoreRequest
): Promise<void> => {
  const resp = await ApiBase.post(`${ORDERS}`, data);
};

export const OrderListRequest = async (
  offset: number,
  page: number
): Promise<IOrderListPagination> => {
  const regional = User.user?.regional_id;
  const resp = await ApiBase.get(`${ORDERS}`, {
    params: { offset, page, origin_regional_id: regional }
  });
  return resp.data as IOrderListPagination;
};

export const OrderShowOneRequest = async (id: number): Promise<IOrderShow> => {
  const resp = await ApiBase.get(`${ORDERS}/${id}`);
  return resp.data as IOrderShow;
};

export const MountOrder = async (id: number): Promise<void> => {
  await ApiBase.put(`${ORDER_STATUS}/${id}`, {
    order_status_id: 3
  });
  await ApiBase.put(`${ORDER_STATUS}/${id}`, {
    order_status_id: 4
  });
  return;
};

export const OrderAction = async (data: IOrderAction): Promise<void> => {
  await ApiBase.post(`${ORDER_ACTIONS}`, data);
  return;
};
